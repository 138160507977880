<template>
  <div class="Construction">
    <simple-c-u-r-d
      ref="construction"
      :is-search="false"
      search-url="/api/inspect/list"
      add-or-update-url="/api/inspect/constructionUpdate"
      delete-url="/api/inspect/stage/delete"
      label-width="150px"
      :show-form="showForm"
      :model="addForm"
      :add-form-rules="addFormRules"
      is-pageable
      :show-add-button="()=>false"
      :show-delete-button="(row)=>showAddButton()&&row.constructionDeviceId"
      :show-edit-button="showAddButton"
      :format-commit-data="formatCommitData"
      :only-read="()=>!showAddButton()"
      operation-width="300px"
    >
      <template #searchOperation>
        <el-button type="primary" plain size="medium" @click="handleUploadFileProject('constructionDocument')">
          施工附件
        </el-button>
        <el-button v-show="showAddButton() && user().role.name !== '施工人员'" type="primary" plain size="medium" @click="inspectFinish">
          完工
        </el-button>
      </template>
      <template #operation="{row}">
        <el-button type="primary" size="small" @click="handleUploadFileProject('img',row,'picture-card',showAddButton())">
          施工图片
        </el-button>
        <el-button type="primary" size="small" @click="handleUploadFileInspect(row)">
          查勘图片
        </el-button>
        <el-button v-if="!showAddButton()" type="primary" size="small" @click="$refs.construction.handleEdit(row)">
          查看
        </el-button>
      </template>
    </simple-c-u-r-d>

    <SimpleUploadFileAllCommit ref="uploadFile"
                               multiple
                               :limit="limitFile"
                               :action="actionFile"
                               :remove-url="removeUrlFile"
                               :all-file-action="allFileAction"
                               accept="*/*"
                               @change="success"
                               @commit="success"
    >
      <template #before>
        <el-form-item label="项目名">
          <el-input v-model="getProjectName" disabled />
        </el-form-item>
      </template>
    </SimpleUploadFileAllCommit>
    <SimpleUploadFileAllCommit ref="uploadImg"
                               multiple
                               :remove-url="removeUrlImg"
                               :limit="limitImg"
                               :action="actionImg"
                               :all-file-action="allImgAction"
                               list-type="picture-card"
                               accept="image/jpeg,image/gif,image/png"
                               @change="$refs.construction.search()"
                               @commit="$refs.construction.search()"
    />

    <el-dialog
      title="查勘图片"
      :visible.sync="inspectDialogVisible"
      width="50%"
    >
      <div v-for="(item,index) in inspectFileSmallList" :key="index" style="display: inline-block;padding-right: 20px;">
        <el-image
          :src="item.url"
          class="image-slot"
          :preview-src-list="inspectSrcList"
          style="border: 1px solid #999999"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inspectDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="inspectDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog
      title="施工照片"
      :visible.sync="constructionDialogVisible"
      width="50%"
    >
      <div v-for="(item,index) in constructionFileSmallList" :key="index" style="display: inline-block;padding-right: 20px;">
        <el-image
          :src="item.url"
          class="image-slot"
          :preview-src-list="constructionSrcList"
          style="border: 1px solid #999999"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="constructionDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="constructionDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import SimpleCURD from '@/components/simple/SimpleCURD'
import ArrayUtils from '@/common/js/ArrayUtils'
import util from '@/common/js/util'
import SimpleUploadFileAllCommit from '@/components/simple/SimpleUploadFileAllCommit'

export default {
  name: 'Construction',
  components: { SimpleUploadFileAllCommit, SimpleCURD },
  inject: ['user', 'reFlushSession'],
  data() {
    const notshow = (type, data) => !data.inspectDeviceId || data.inspectDeviceId !== 1
    const checkSerialNo = (rule, value, callback) => {
      if (!value && ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.haveSerialNo) {
        callback(new Error('请输入设备序列号'))
      }
      callback()
    }
    return {
      serialNoOptions: [],
      addForm: {
        projectId: 'sdf',
        location: null,
        imagePath: null,
        constructionImagePath: null,
        serialNo: null,
        serialPort: null,
        infoTransmitLocation: null,
        brand: null,
        network: null,
        g4: null,
        nb: null,
        havePowerSupply: null,
        filePath: null,
        description: null,
        inspectDeviceId: null,
        constructionDeviceId: null,
        watchLocation: null,
        fileButton: true,
      },
      showForm: [
        {
          param: 'projectId',
          disabled: (type) => type !== 'search',
          name: '项目名称',
          search: true,
          type: 'select',
          hidden: true,
          filterable: true,
          clearable: true,
          option: () => this.user().projectList.filter(item => {
            return item.stage.ordinal >= 1 && item.type.ordinal === 0
          }).map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => {
            let name
            this.user().projectList.forEach(project => {
              if (project.id === row.projectId) {
                name = project.name
              }
            })
            return name
          },
        },
        {
          param: 'inspectDeviceId',
          name: '设备类型',
          search: true,
          type: 'select',
          clearable: true,
          disabled: (type) => type !== 'search',
          option: () => this.inspectDevice.map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => <span>{ArrayUtils.getArrayObject(this.inspectDevice, 'id', row.inspectDeviceId)?.name}</span>,
        },
        {
          param: 'constructionDeviceId',
          name: '设备',
          search: true,
          type: 'select',
          disabled: (type) => type !== 'search' && !this.showAddButton(),
          clearable: true,
          blur: (type) => {
            if (this.addForm.constructionDeviceId) {
              this.$refs.construction.$refs.simpleCURDAddForm.$refs.simpleForm.validateField('constructionDeviceId')
            }
            this.serialNoOptions = []
          },
          option: (type) => ArrayUtils.getArrayByKeyToObject(this.constructionDevice, 'inspectDeviceId', type === 'search' ? this.$refs.construction.searchAddForm.inspectDeviceId : this.addForm.inspectDeviceId)?.filter(item => {
            return this.constructionDeviceName.includes(item.name) || !item.haveSerialNo
          }).map(item => {
            return {
              label: item.name,
              value: item.id,
            }
          }),
          formatter: (row) => <span>{ArrayUtils.getArrayObject(this.constructionDevice, 'id', row.constructionDeviceId)?.name}</span>,
        },
        {
          param: 'serialNo',
          name: '设备序列号',
          type: 'slot',
          slotName: 'serialNo',
          content: (type, data) => {
            const remoteMethod = (query) => {
              if (query !== '') {
                if (!this.addForm.constructionDeviceId) {
                  this.$refs.construction.$refs.simpleCURDAddForm.$refs.simpleForm.validateField('constructionDeviceId')
                  return
                }
                this.$get('/api/material/getAll', { serialNo: query, projectId: data.projectId, constructionId: this.addForm.constructionDeviceId, constructionDevice: ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.name }).then(result => {
                  this.serialNoOptions = result.data.map(item => {
                    return { label: item.serialNo, value: item.serialNo }
                  })
                })
              } else {
                this.serialNoOptions = []
              }
            }
            return <el-select
              style="width:100%"
              clearable
              disabled={type !== 'search' && !this.showAddButton()}
              vModel={this.addForm.serialNo}
              filterable
              remote
              reserveKeyword
              placeholder="请输入设备序列号"
              remoteMethod={remoteMethod}>
              {this.serialNoOptions.map(item => {
                return <el-option
                  label={item.label}
                  value={item.value}>
                </el-option>
              })}
            </el-select>
          },
          clearable: true,
          notShow: () => this.addForm.constructionDeviceId ? !ArrayUtils.getArrayObject(this.constructionDevice, 'id', this.addForm.constructionDeviceId)?.haveSerialNo : true
        },
        {
          param: 'serialNo',
          name: '设备序列号',
          search: true,
          clearable: true,
          hidden: true,
          notShow: (type) => type !== 'search'
        },
        { param: 'serialPort', name: '端口号', disabled: () => !this.showAddButton(), clearable: true, maxlength: '100' },
        {
          param: 'stage',
          name: '阶段',
          width: '100px',
          search: true,
          type: 'select',
          disabled: (type) => type !== 'search',
          clearable: true,
          option: () => [{ label: '未施工', value: 0 }, { label: '施工完毕', value: 1 }],
          formatter: (row) => {
            switch (row.stage) {
            case 0:return <span>未施工</span>
            case 1:return <span>施工完毕</span>
            default:return <span></span>
            }
          },
        },
        { param: 'location', name: '点位信息', search: true, disabled: () => !this.showAddButton(), clearable: true, maxlength: '100' },
        { param: 'watchLocation', name: '监控位置', search: true, disabled: () => !this.showAddButton(), clearable: true, maxlength: '100' },
        {
          param: 'havePowerSupply',
          name: '是否有电源',
          width: '100px',
          type: 'radio',
          disabled: (type) => type !== 'search',
          clearable: true,
          radio: [{ label: '有', value: 0 }, { label: '无', value: 1 }],
          formatter: (row) => {
            switch (row.havePowerSupply) {
            case 0:return <el-tag type="success">有电源</el-tag>
            case 1:return <el-tag type="info">无电源</el-tag>
            default:return <el-tag type="info">未定义</el-tag>
            }
          },
        },
        { param: 'g4', name: '4G信号强度(移动)', type: 'select', clearable: true, disabled: (type) => type !== 'search', hidden: true, option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }] },
        { param: 'nb', name: 'NG信号强度(移动)', clearable: true, disabled: (type) => type !== 'search', type: 'select', hidden: true, option: [{ value: 3, label: '好' }, { value: 4, label: '中' }, { value: 5, label: '差' }, { value: 6, label: '无' }] },
        { param: 'brand', name: '主机品牌', disabled: (type) => type !== 'search', clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        { param: 'model', name: '主机型号', disabled: (type) => type !== 'search', clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        { param: 'connectionMode', name: '连接方式', disabled: (type) => type !== 'search', clearable: true, hidden: true, maxlength: '100', notShow: notshow },
        {
          param: 'constructionCreateTime',
          name: '施工时间',
          width: '100px',
          disabled: () => !this.showAddButton(),
          type: 'date',
          date: 'date',
          notShow: true,
          formatter: (row) => {
            return util.formatDate.format(row.createTime, 'yyyy-MM-dd')
          }
        },
        { param: 'description', name: '备注', disabled: () => !this.showAddButton(), clearable: true, hidden: true, maxlength: '1000' },
      ],
      addFormRules: {
        projectId: [{ required: true, message: '请选择项目名称', trigger: 'blur' }],
        serialNo: [{ validator: checkSerialNo }],
        constructionDeviceId: [{ required: true, message: '请选择设备', trigger: 'blur' }],
      },
      // device start
      inspectDialogVisible: false,
      inspectFileSmallList: [],
      inspectSrcList: [],
      constructionDialogVisible: false,
      constructionFileSmallList: [],
      constructionSrcList: [],
      limitFile: null,
      actionFile: '',
      removeUrlFile: '',
      allFileAction: '',
      showButton: true,
      constructionDevice: [],
      inspectDevice: [],
      removeUrlImg: '',
      limitImg: null,
      actionImg: '',
      allImgAction: '',
      constructionDeviceName: []
    }
  },
  computed: {
    getProjectName() {
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.construction.searchAddForm.projectId)?.name
    },
  },
  mounted() {
    this.$watch(() => this.$refs.construction.searchAddForm.projectId, () => {
      this.showButton = this.changeShowButton()
      this.$refs.construction.search()
      this.getDeviceName()
    })
    this.user().projectList.filter(item => {
      return item.stage.ordinal >= 1 && item.type.ordinal === 0
    })
    // if (projectList && projectList.length > 0) {
    //   this.$set(this.$refs.construction.searchAddForm, 'projectId', projectList[0].id)
    // }
  },
  created() {
    this.reFlushSession()
    this.getInspectDevice()
    this.getConstructionDevice()
  },
  methods: {
    getDeviceName() {
      this.$get('/api/material/getDeviceName?projectId=' + this.$refs.construction.searchAddForm.projectId).then(result => {
        this.constructionDeviceName = result.data
      })
    },
    getConstructionDevice() {
      this.$get('/api/constructionDevice/getAll').then(result => {
        this.constructionDevice = result.data
      })
    },
    getInspectDevice() {
      this.$get('/api/inspectDevice/getAll').then(result => {
        this.inspectDevice = result.data
      })
    },
    showProjectFileUrl() {
      if (!this.getArrayObject()) {
        return '#'
      }
      return '/api/project/download/constructionDocument/' + this.getArrayObject().id
    },
    getArrayObject() {
      if (!this.$refs.construction) {
        return '#'
      }
      return ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.construction.searchAddForm.projectId)
    },
    changeShowButton() {
      return this.getArrayObject() ? this.getArrayObject().constructionDocumentPath : false
    },
    handleUploadFileProject(path, row, pic, isConstruction) {
      let fileList = []
      if (path === 'img') {
        if (row.constructionImagePath) {
          fileList = row.constructionImagePath.split(',').map((item, i) => {
            return {
              name: i,
              url: `/api/inspect/download?path=${encodeURI(item)}&projectId=${this.$refs.construction.searchAddForm.projectId}&flag=construction`,
              fileName: encodeURI(item),
              index: i
            }
          })
        }
        if (isConstruction) {
          this.$refs.uploadImg.fileList = fileList
          this.$refs.uploadImg.visible()
          this.removeUrlImg = `/api/inspect/delete/${row.id}/${path}?projectId=${this.$refs.construction.searchAddForm.projectId}&type=construction`
          this.actionImg = `/api/inspect/upload/${path}?id=${row.id}&projectId=${this.$refs.construction.searchAddForm.projectId}&type=construction`
          this.allImgAction = `/api/inspect/download/all?id=${row.id} &projectId=${this.$refs.construction.searchAddForm.projectId}&type=construction`
        } else {
          this.constructionFileSmallList = []
          let imgList = []
          this.constructionSrcList = []
          if (row.imagePath) {
            this.constructionFileSmallList = row.constructionImagePath.split(',').map((item, i) => {
              return {
                name: i,
                url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=true&projectId=${this.$refs.construction.searchAddForm.projectId}&flag=construction`,
                fileName: encodeURI(item),
                index: i
              }
            })
            imgList = row.constructionImagePath.split(',').map((item, i) => {
              return {
                name: i,
                url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.construction.searchAddForm.projectId}&flag=construction`,
                fileName: encodeURI(item),
                index: i
              }
            })
            imgList.forEach((item) => {
              this.constructionSrcList.push(item.url)
            })
          }
          this.constructionDialogVisible = true
        }
      } else {
        this.reFlushSession().then(() => {
          const project = this.getArrayObject()
          if (!project) {
            this.$OneMessage.warning('暂无施工项目')
            return
          }
          if (project.constructionDocumentPath) {
            fileList = project.constructionDocumentPath.split(',').map((item, i) => {
              return {
                name: `${item}`,
                url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.construction.searchAddForm.projectId}&type=${path}`,
                fileName: encodeURI(item),
              }
            })
          }
          this.$refs.uploadFile.fileList = fileList
          this.removeUrlFile = `/api/project/delete/${path}/${project.id}`
          this.actionFile = `/api/project/upload/${path}?id=${project.id}`
          this.allFileAction = `/api/project/download/${path}/all?id=${project.id}`
          this.reFlushSession().then(() => this.$refs.uploadFile.visible())
        })
      }
    },
    handleUploadFileInspect(row) {
      this.inspectFileSmallList = []
      let imgList = []
      this.inspectSrcList = []
      if (row.imagePath) {
        this.inspectFileSmallList = row.imagePath.split(',').map((item, i) => {
          return {
            name: i,
            url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=true&projectId=${this.$refs.construction.searchAddForm.projectId}&flag=inspect`,
            fileName: encodeURI(item),
            index: i
          }
        })
        imgList = row.imagePath.split(',').map((item, i) => {
          return {
            name: i,
            url: `/api/inspect/download?path=${encodeURI(item)}&isCompress=false&projectId=${this.$refs.construction.searchAddForm.projectId}&flag=inspect`,
            fileName: encodeURI(item),
            index: i
          }
        })
        imgList.forEach((item) => {
          this.inspectSrcList.push(item.url)
        })
      }
      this.inspectDialogVisible = true
    },
    deleteFileProject(path) {
      this.$confirm('确认删除项目 ' + this.getArrayObject().name + '的附件吗？', '删除').then(() => {
        this.$post(`/api/project/delete/${path}/` + this.getArrayObject().id).then(() => {
          this.$refs.construction.search()
          this.reFlushSession()
          this.$forceUpdate()
          this.showButton = false
        })
      })
    },
    inspectFinish() {
      const project = ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.construction.searchAddForm.projectId)
      this.$confirm(project?.name + '项目确认完工吗？', '提示').then(() => {
        this.$get('/api/project/updateFinish', {
          projectId: this.$refs.construction.searchAddForm.projectId,
          stage: 'FINISHED',
        }).then(() => {
          this.reFlushSession()
        })
      })
    },
    getCurrentProject() {
      return this.$refs.construction?.searchAddForm.projectId ? ArrayUtils.getArrayObject(this.user().projectList, 'id', this.$refs.construction?.searchAddForm.projectId) : null
    },
    showAddButton() {
      const ordinal = this.getCurrentProject()?.stage.ordinal
      return ordinal === 1
    },
    success() {
      this.$refs.construction.search()
      this.showButton = true
    },
    formatCommitData(data, row) {
      data.forEach(item => {
        if (item.network) {
          item.g4 = JSON.parse(item.network)[0]
          item.nb = JSON.parse(item.network)[1]
        }
        item.status = item.status.ordinal
      })
      return data
    },
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
  color: #409EFF;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
  color: #409EFF;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
  color: #FFF;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
  color: #FFF;
}
.image-slot{
  width: 100px;
  height: 100px;
  background-color: #c9c9c9;
  vertical-align: middle;
}
</style>
